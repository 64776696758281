<template>
    <div>
        <router-view>
            <div class="hero-head">
                
                <nav class="navbar my-3">
                    <div class="container">
                        <div class="navbar-brand">
                            <a href="../">
                                <img src="./images/OneLiner NoirBlanc.svg" width="250">    
                            </a>                            
                            <div class="navbar-burger burger" data-target="navbarMenu" :class="{'is-active':isBurgerActive}" @click="isBurgerActive=!isBurgerActive">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>                            
                        </div>
                        <div id="navbarMenu" class="navbar-menu" :class="{'is-active':isBurgerActive}">
                            <div class="navbar-start">
                                <router-link class="navbar-item" to="/" @click.native="isBurgerActive=!isBurgerActive">Home</router-link>
                                <router-link class="navbar-item" to="/purpose" @click.native="isBurgerActive=!isBurgerActive">Purpose</router-link>
                                <router-link class="navbar-item" to="/intent" @click.native="isBurgerActive=!isBurgerActive">Intent</router-link>
                                <router-link class="navbar-item" to="/knowledge" @click.native="isBurgerActive=!isBurgerActive">Knowledge</router-link>
                                <router-link class="navbar-item" to="/about" @click.native="isBurgerActive=!isBurgerActive">About</router-link>
                            </div>                            
                            <div class="navbar-end">
                                <router-link class="navbar-item" to="/signin" @click.native="isBurgerActive=!isBurgerActive">Login</router-link>
                                <span class="navbar-item">
                                    <router-link to="/register" class="button is-outlined is-white" @click.native="isBurgerActive=!isBurgerActive">
                                        <span class="icon">
                                            <i class="fas fa-user"></i>
                                        </span>
                                        <span>Create Account</span>
                                    </router-link>
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            isBurgerActive: false,
        }
    }
}
</script>

<style lang="scss">
@import '@/design/index.scss';
</style>

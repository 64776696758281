import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    //component: Home
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/index.html',
    name: 'Index',
    //component: Home
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },  
  {
    path: '/purpose',
    name: 'Purpose',
    //component: Purpose
    component: () => import(/* webpackChunkName: "purpose" */ '../views/Purpose.vue')
  },
  {
    path: '/intent',
    name: 'Intent',
    //component: Intent
    component: () => import(/* webpackChunkName: "intent" */ '../views/Intent.vue')
  },  
  {
    path: '/knowledge',
    name: 'Knowledge',
    //component: Knowledge
    component: () => import(/* webpackChunkName: "knowledge" */ '../views/Knowledge.vue')
  },   
  {
    path: '/signin',
    name: 'Signin',
    component: () => import(/* webpackChunkName: "signin" */ '../views/Signin.vue')
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue')
  },  
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },  
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import('../views/PageNotFound.vue') 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
